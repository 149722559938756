import React from "react";

import "../styles/signup.css";

const SignupForm = () => {
    return <div id="mc_embed_signup">
        <form action="https://kwa.us10.list-manage.com/subscribe/post?u=264938e34149845a72b8a3cdb&amp;id=d96209c259" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
            <div style={{ position: 'absolute', left: '-5000px'}} aria-hidden="true"><input type="text" name="b_264938e34149845a72b8a3cdb_d96209c259" tabindex="-1" value=""/></div>
            <div class="mc-field-group">
                <input placeholder="Email Address" type="email" name="EMAIL" class="required email" id="mce-EMAIL"/>
                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button"/></div>
            </div>
            <div id="mce-responses" class="clear">
                <div class="response" id="mce-error-response" style={{ display: 'none' }}></div>
                <div class="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div> 
        </form>
    </div>
}

export default SignupForm;