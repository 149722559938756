import React from "react"
import Color from "color"
import {Link} from "gatsby"

const Header = ({
  title,
  height,
  layers,
  color
}) => {
  const layerHeight = (height / layers) * (3 / 2);
  const layerWidth = 2000;
  const offsetY = height - (layerHeight * .66);
  const maxStepSize = 20;

  const baseColor = Color(color);

  let sunX = Math.random()*.5+.25;
  let sunY = Math.random()*.1+.2;

  let defs = [];
  let paths = [];
 
  for (let layer=1; layer<=layers; layer++) {

    let layerAnchorY = layer * (layerHeight / layers) + offsetY + (Math.random() * (layerHeight / layers));
    let coord = `M 0 ${layerAnchorY} `;

    // back layers have higher peaks for more mountainous effect
    let amplitude = (Math.random() * (layerHeight / layers)) + (layerHeight / layer / layers);  
    let freq1 = (Math.random() * 0.02 * layer / layers) + .005;
    let freq2 = Math.random() * 0.01 + .005;
    let phase1 = Math.random() * 500;
    let phase2 = phase1 + (Math.random() * 250);
 
    let stepSize = (
      1 + Math.round(Math.random() * maxStepSize / 3) * 3
    )
    for (let i=0; i<layerWidth + maxStepSize; i+=stepSize) {
      let renderStep = stepSize === 1;
      coord += `${renderStep ? 'H' : 'L'} ${i} ${renderStep ? 'V' : ''} ${
        (Math.sin(freq1*(i + phase1)) * amplitude) + // base
        (Math.sin(freq2*(i + phase2)) * amplitude / 2) + // interference
        (Math.sin(i * layer / 5) * Math.log(layer) / 4) + // detail
        (Math.sin(i * layer / 3) * Math.log(layer * 2, 10) / 2) + // detail
        (Math.sin(i * layer / 2) * (Math.log(layer, 2) * 1.5)) + // more detail
        (layerAnchorY - layerHeight)
      } `;
    }

    coord += `V ${layerAnchorY} L 0 ${layerAnchorY}`;
    defs.push(
      <radialGradient id={`fill${layer}`} cx={sunX} cy={(sunY / 2) - ((layer - 1) / Math.pow(layers, 2))}>
        <stop offset="0%" stop-color={baseColor.rotate(120).saturate(.3).lighten(.3).desaturate(.1*layer).darken(.1*layer).string()} /> 
        <stop offset={`${(20 / layer)}%`} stop-color={baseColor.saturate(.3).desaturate((layer / layers) / 4).darken((layer / layers) / 2).rotate(120 - (45 * (layer / layers))).string()} />
        <stop offset="100%" stop-color={baseColor.saturate((layer / layers) / 2.5).darken((layer / layers)).string()} />
      </radialGradient>
    );
    paths.push(<path d={coord} fill={`url('#fill${layer}')`}/>);
  }

  return <Link style={{
    display: 'block',
    boxShadow: `none`,
    textDecoration: `none`,
    color: `inherit`,
    height
  }} to="/">
    <h3
      style={{
        fontFamily: `Bebas Neue, sans-serif`,
        textAlign: 'center',
        letterSpacing: '1rem',
        fontSize: '5vw',
        color: '#fff',
        textShadow: '0px 4px 0px rgba(0,0,0,.25)',
        marginTop: 0,
        position: 'fixed',
        width: '100%',
        left: '0px',
        top: '9vw',
        transform: 'perspective(700px) rotateX(17deg)',
        zIndex: 1
      }}
    >
      {title}
    </h3>
    <svg style={{
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'hidden',
      zIndex: 0
    }} width="100%" height={height} viewBox={`0 0 ${layerWidth - maxStepSize} 100`} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin">
      <defs>
        <radialGradient id={`fillBg`} cx={sunX} cy={sunY}> 
          <stop offset="0" stop-color={baseColor.rotate(120).desaturate(1).lighten(1).string()} /> 
          <stop offset="5%" stop-color={baseColor.rotate(120).lighten(.7).string()} /> 
          <stop offset="20%" stop-color={baseColor.rotate(90).desaturate(.5).lighten(.4).string()} /> 
          <stop offset="60%" stop-color={baseColor.rotate(0).desaturate(.5).lighten(.2).string()} /> 
          <stop offset="90%" stop-color={baseColor.rotate(0).desaturate(.5).darken(.1).string()} />
          <stop offset="100%" stop-color={baseColor.rotate(0).desaturate(.5).darken(.2).string()} />
        </radialGradient>
        {defs.slice(0, Math.floor(layers/2))}
        <filter id="noise">
          <feTurbulence type="fractalNoise" baseFrequency="30" result="noisy" />
          <feColorMatrix type="saturate" values="0"/>
          <feBlend in="SourceGraphic" in2="noisy" mode="multiply" />
        </filter>
      </defs>
      <rect filter="url(#noise)" x="0" y="0" width="100%" height={height} fill="url(#fillBg)" />
      <circle cx={sunX * layerWidth} cy={sunY * height} r="20" fill={baseColor.rotate(180).desaturate(.8).lighten(.8).string()} />
      {paths.slice(0, Math.floor(layers/2))}
    </svg>
    <svg style={{
      position: 'absolute',
      top: 0,
      left: 0,
      overflow: 'hidden',
      zIndex: 2
    }} width="100%" height={height} viewBox={`0 0 ${layerWidth - maxStepSize} 100`} xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMin">
      <defs>
        {defs.slice(Math.floor(layers/2))}
        <linearGradient id="fog" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="rgb(255,255,255)" stop-opacity={(1 - (sunY * 2))} />
          <stop offset="100%" stop-color="rgb(255,255,255)" stop-opacity="0" />
        </linearGradient>
      </defs>
      <rect x="0" y="0" width="100%" height={height} fill="url(#fog)" />
      {paths.slice(Math.floor(layers/2))}
    </svg>
  </Link>
};

export default Header