import React from "react"
import { Link } from "gatsby"
import Color from "color"

import Header from "../components/header";
import { rhythm, scale } from "../utils/typography"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    const themeColor = Color("#5aa37c").rotate((180 * Math.random()));
    const headerHeight = 480;
    let header

    if (location.pathname === rootPath) {
    }
    
    return (
      <div>
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
            paddingBottom: 0
          }}
        >
          <header style={{ display: 'block', height: "23vw", overflow: 'hidden' }}><Header title={title} height={headerHeight} layers={4+Math.round(Math.random())} color={themeColor.string()}/></header>
        </div>
        <div style={{
            background: '#000',
            zIndex: 2,
            position: 'relative',
            width: '100%',
            paddingTop: `${rhythm(3 / 4)}`
          }}>
          <div
            style={{
              marginLeft: `auto`,
              marginRight: `auto`,
              maxWidth: rhythm(24),
              padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              paddingTop: 0
            }}
          >
            <main>{children}</main>
            <footer style={{fontSize: '11px', textAlign: 'right'}}>
              © {new Date().getFullYear()} @kevinverse
            </footer>
          </div>
        </div>
      </div>
    )
  }
}

export default Layout
