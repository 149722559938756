import Typography from "typography"
import Wordpress2016 from "typography-theme-wordpress-2016"

Wordpress2016.overrideThemeStyles = () => {
  return {
    "a.gatsby-resp-image-link": {
      boxShadow: `none`,
    },
  }
}

delete Wordpress2016.googleFonts

const typography = new Typography({
  baseFontSize: "18px",
  baseLineHeight: 1.666,
  googleFonts: [{
    name: "Bebas Neue",
    styles: ["400", "500", "700"]
  }, {
    name: "Sulphur Point",
    styles: ["400"]
  }, {
    name: "Noto Sans",
    styles: ["400", "700"]
  }],
  headerFontFamily: [
    "Sulphur Point",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  bodyFontFamily: [
    "Noto Sans",
    "Helvetica Neue",
    "Segoe UI",
    "Helvetica",
    "Arial",
    "sans-serif",
  ],
  headerWeight: '300',
  headerColor: '#fff',
  bodyColor: 'rgba(255,255,255,.9)',
  overrideStyles: {
    'h1': { marginTop: "1.5em", marginBottom: '0px' }
  }
})

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles()
}

export default typography
export const rhythm = typography.rhythm
export const scale = typography.scale
