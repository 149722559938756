/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile.jpg/" }) {
        childImageSharp {
          fixed(width: 60, height: 60, quality: 90) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author}
        style={{
          marginRight: rhythm(1/2),
          marginBottom: 0,
          minWidth: 60,
          borderRadius: `5px`,
        }}
        imgStyle={{
          borderRadius: `5px`,
        }}
      />
      <p style={{ 
        fontSize: '14px',
      }}>
        <strong>{author}</strong> is the CEO of <a href="https://fossa.com">FOSSA</a>, an enterprise software & developer tools company based out of San Francisco.
        <br/>
        <a href="https://twitter.com/kevinverse?ref_src=twsrc%5Etfw" class="twitter-follow-button" data-show-count="false">Follow @kevinverse</a><script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
      </p>
    </div>
  )
}

export default Bio
